import React from 'react';

import vehicleList from '@/constants/vehicleList';

import Catalog from '@/components/Catalog';

import generateVehiclePageUrlById from "@/utils/generateVehiclePageUrlById"

import filterListOriginal from './constants/filterList';

const VehicleCatalogContainer = () => (
  <Catalog
    filterListOriginal={filterListOriginal}
    itemList={vehicleList}
    itemNameList={vehicleList}
    pageTitle='Каталог существ'
    pageUrlGenerator={generateVehiclePageUrlById}
  />
)

export default VehicleCatalogContainer
