const {ATTACK_RANGE_WEAPON} = require('./../../../../attackTypeList')
const {GEAR_LANTERN_HOODED} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {MASS_UNIT_TON} = require('./../../../../massUnitList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_GOS} = require('./../../../../sourceList')
const {SPEED_SWIM} = require('./../../../../speedList')
const {VEHICLE_GALLEY} = require('./../../../../vehicleIdList')
const {vehicleRules} = require('./../../../vehicleCommonDescription')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../../paramList')
const {
  CREATURE_BANDIT_CAPTAIN,
  CREATURE_COMMONER,
  CREATURE_GUARD,
  CREATURE_SCOUT,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_INCAPACITATED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')

module.exports = {
  name: 'Галера',
  nameEn: 'Galley',
  id: VEHICLE_GALLEY,
  description: [
    `Галеры — длинные суда, для передвижения которых нужны вёсла и большая гребная команда.

Эти корабли могут нести осадные орудия и солдат на войну или перевозить множество торговых грузов.

Независимо от назначения корабля, команда почти всегда нанимает дополнительную охрану, поскольку галеры представляют собой большие, богатые грузом цели для пиратов.`,
    {
      header: `Особенности галеры`,
      text: `### Потолки

Потолки на нижней палубе галеры высотой 8 футов.

### Освещение

По всему судну развешены ярко светящие [подвесные фонари](GEAR:${GEAR_LANTERN_HOODED}).

### Такелаж

По такелажу корабля можно взбираться без проверок способностей.

### Паруса и вёсла

Галера ловит ветер парусом на единственной 120-футовой мачте. Веслами гребут сидящие на нижней палубе [гребцы](CREATURE:${CREATURE_COMMONER}).`,
      source: {
        id: SOURCE_GOS,
        page: 188,
      },
    },
    {
      header: `Пример экипажа галеры`,
      text: `Галере нужен экипаж из восьмидесяти человек, чтобы правильно управлять судном или грести, перевозя дополнительных пассажиров или солдат.

Если персонажи — лишь пассажиры, то команда состоит из следующих существ, каждый из которых владеет водным транспортом, помимо своим обычных навыков:

* Один капитан ([капитан разбойников](CREATURE:${CREATURE_BANDIT_CAPTAIN}))
* Пятеро других офицеров: старший помощник, боцман, рулевой, хирург, и кок ([разведчики](CREATURE:${CREATURE_SCOUT}))
* Двадцать два матроса ([обыватели](CREATURE:${CREATURE_COMMONER}))
* Двадцать осадных инженеров ([стражи](CREATURE:${CREATURE_GUARD}))
* Двадцать [стражей](CREATURE:${CREATURE_GUARD})`,
      source: {
        id: SOURCE_GOS,
        page: 188,
      },
    },
    {
      header: `Верхняя палуба`,
      text: `Особенности верхней палубы галеры

### Баллисты

Четыре баллисты прикреплены к палубе в носу корабля. Десять стрел надёжно закреплены возле каждой из них.
  
### Мангонели

Два мангонелы прикреплены к палубе на корме. Возле каждой катапульты сложены и надёжно закреплены 10 камней.

### Морской таран

В корме галеры находится железный морской таран, используемый для атаки других кораблей.

### Проём

Проём в центре верхней палубы от кормы до носа размером 10 на 80 футов открывает небу нижнюю палубу.

### Релинги

Трёхфутовый релинг окружает периметр палубы, создавая укрытие на половину для стоящих за ним Средних существ и укрытие на три четверти для Маленьких.

### Лодки

Восемь лодок размешены на этой палубе в две группы по четыре. Тросы и блоки позволяют опускать их на воду и поднимать на борт.

### Барабан

Барабан с двумя прикреплёнными к нему молотками находится на корме верхней палубы, перед спуском на нижнюю палубе. Член экипажа играет на нём, когда гребцы гребут на нижней палубе. Ритм помогает им грести синхронно.

### Штурвал

Штурвал галеры находится на корме верхней палубы.`,
      source: {
        id: SOURCE_GOS,
        page: 189,
      },
    },
    {
      header: `Нижняя палуба`,
      text: `Тесная нижняя палуба галеры источает запах пота и имеет следующие особенности.

### Грузовой отсек

Груз на носу и корме нижней палубы включает ящики, бочки и снаряжение, закреплённое тросами.

### Вёсла

Тридцать две скамьи закреплены на палубе, с 20-футовым веслом у каждой. Когда судно идёт на вёслах, матросы сидят на этих скамьях и гребут. На стенах висят десять запасных весёл.`,
      source: {
        id: SOURCE_GOS,
        page: 189,
      },
    },
    vehicleRules,
  ],
  sizeType: SIZE_GARGANTUA,
  proportions: [130, 20],
  cargo: {
    unitId: MASS_UNIT_TON,
    value: 150,
  },
  creatureCapacity: {
    crew: 80,
    passengers: 40,
  },
  travelSpeed: 4,
  source: {
    id: SOURCE_GOS,
    page: 188,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 4,
    [PARAM_CON]: 20,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_INCAPACITATED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_STUNNED,
    CONDITION_UNCONSCIOUS,
  ],
  actionsInfo: `На своём ходу ★ТРАНСПОРТ★ может совершать

* 3 действия, если на ★нём★ 40 или больше экипажа,
* 2 действия, если на ★нём★ меньше 40 экипажа,
* 1 действие, если на ★нём★ меньше 20 экипажа,
* 0 действий, если на ★нём★ меньше 3 экипажа`,
  actionList: [
    {
      name: 'Залп баллист',
      description: `★ТРАНСПОРТ★ стреляет из _Баллист_.`,
    },
    {
      name: 'Залп мангонелей',
      description: `★ТРАНСПОРТ★ стреляет из _Мангонелей_.`,
    },
    {
      name: 'Передвижение',
      description: `★ТРАНСПОРТ★ использует _Рубку_ для передвижения с помощью _Вёсел_ или _Парусов_.

Частью этого передвижения ★он★ может использовать _Морской таран_.`,
    },
  ],
  hull: {
    ac: 15,
    hp: 500,
    damageThreshold: 20,
  },
  controlList: [
    {
      name: 'Рубка',
      ac: 16,
      hp: 50,
      description: `★ТРАНСПОРТ★ передвигается со скоростью, развиваемой _Вёслами_ или _Парусами_, с одним поворотом на 90°.

Если _Рубка_ разрушена, ★ТРАНСПОРТ★ не может передвигаться.`,
    },
  ],
  movementList: [
    {
      name: 'Вёсла',
      ac: 12,
      hp: {
        value: 100,
        comment: '−5 футов за каждые полученные 25 урона',
      },
      speedList: [
        {
          comment: 'водная',
          speed: {
            [SPEED_SWIM]: {
              value: 30,
              comment: `требуется не менее 40 экипажа`,
            },
          },
        },
      ],
    },
    {
      name: 'Паруса',
      ac: 12,
      hp: {
        value: 100,
        comment: '−10 футов за каждые полученные 25 урона',
      },
      speedList: [
        {
          comment: 'водная',
          speed: {
            [SPEED_SWIM]: [
              35,
              {
                value: 15,
                comment: `против ветра`,
              },
              {
                value: 50,
                comment: `по ветру`,
              },
            ],
          },
        },
      ],
    },
  ],
  weaponList: [
    {
      name: 'Баллиста',
      ac: 15,
      hp: 50,
      count: 4,
      action: {
        attack: {
          type: ATTACK_RANGE_WEAPON,
          bonus: 6,
          range: {
            normal: 120,
            max: 480,
          },
          hit: {
            type: DAMAGE_PIERCING,
            diceCount: 3,
            diceType: 10,
          },
        },
      },
    },
    {
      name: 'Мангонель',
      ac: 15,
      hp: 100,
      count: 2,
      action: {
        attack: {
          type: ATTACK_RANGE_WEAPON,
          bonus: 5,
          range: {
            min: 60,
            normal: 200,
            max: 800,
          },
          hit: {
            type: DAMAGE_BLUDGEONING,
            diceCount: 5,
            diceType: 10,
          },
        },
      },
    },
    {
      name: 'Морской таран',
      ac: 20,
      hp: 100,
      damageThreshold: 10,
      action: {
        description: `★ТРАНСПОРТ★ получает преимущество на все испытания, вызванные столкновением, когда ★он★ врезается в существо или предмет. Весь полученный от столкновения урон получает _Морской таран_, а не ★сам★ ★ТРАНСПОРТ★.

Эти преимущества не применяются, если в ★ТРАНСПОРТ★ врезается другое судно.`,
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
