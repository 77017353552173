const {GENDER_FEMALE} = require('./../../../../genderList')
const {MASS_UNIT_TON} = require('./../../../../massUnitList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_GOS} = require('./../../../../sourceList')
const {SPEED_SWIM} = require('./../../../../speedList')
const {VEHICLE_ROWBOAT} = require('./../../../../vehicleIdList')
const {vehicleRules} = require('./../../../vehicleCommonDescription')
const {
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_INCAPACITATED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')

module.exports = {
  name: 'Лодка',
  nameAlt: 'Шлюпка',
  nameEn: 'Rowboat',
  id: VEHICLE_ROWBOAT,
  description: [
    `Скромные лодки доставляют пассажиров к большим судам и от них, и позволяют плавать по рекам и озёрам.

У лодок простая, универсальная конструкция. На них нет ни палуб, ни постоянной команды.

Из-за своего веса в 100 фунтов, лодку легко переносить и перевозить на больших судах.`,
    vehicleRules,
  ],
  sizeType: SIZE_LARGE,
  proportions: [10, 5],
  cargo: {
    unitId: MASS_UNIT_TON,
    value: 0.25,
  },
  creatureCapacity: {
    crew: 2,
    passengers: 2,
  },
  travelSpeed: {
    hour: 3,
    day: 24,
  },
  source: {
    id: SOURCE_GOS,
    page: 188,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 11,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_INCAPACITATED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
    CONDITION_UNCONSCIOUS,
  ],
  actionsInfo: `На своём ходу ★ТРАНСПОРТ★ может совершать 1 действие. ★Он★ не может совершать действий, если на ★нём★ нет экипажа.`,
  actionList: [
    {
      name: 'Передвижение',
      description: `★ТРАНСПОРТ★ передвигается на _Вёслах_.`,
    },
  ],
  hull: {
    ac: 11,
    hp: 50,
  },
  controlAndMovementList: [
    {
      name: 'Вёсла',
      ac: 12,
      hp: 25,
      speedList: [
        {
          comment: 'водная',
          speed: {
            [SPEED_SWIM]: 15,
          },
        },
      ],
    },
  ],
  genderId: GENDER_FEMALE,
}
