const {ATTACK_RANGE_WEAPON} = require('./../../../../attackTypeList')
const {MASS_UNIT_TON} = require('./../../../../massUnitList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_AI} = require('./../../../../sourceList')
const {SPEED_FLY} = require('./../../../../speedList')
const {VEHICLE_BATTLE_BALLOON} = require('./../../../../vehicleIdList')
const {vehicleRules} = require('./../../../vehicleCommonDescription')
const {
  CREATURE_EFREETI,
  CREATURE_KEG_ROBOT,
  CREATURE_TYRANNOSAURUS_REX,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_INCAPACITATED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')

module.exports = {
  name: 'Боевой дирижабль',
  nameEn: 'Battle Balloon',
  id: VEHICLE_BATTLE_BALLOON,
  description: [
    `Воздушные корабли достаточно редки в Корпорации Приобретений, чей флот, как считается, не превышает пяти таких судов. Но неизмеримая польза от них как в торговле, так и в бою сделало стандартный летающий корабль самым желанным обновлением штаба любой команды.`,
    {
      header: `Происхождение и применение`,
      text: `Коммерческая тайна и агрессивный маркетинг делают неясным, откуда появились дирижабли. Некоторые говорят, что они были оригинальным изобретением, отдела жестоких естественно скрытых тайн (ЖЕСТь) Корпорации Приобретений. Но волшебники Халруаа в прошлом создавали похожие небесные корабли, да и у Культ Дракона были замечены собственные. Всё же мало фракций и организаций известны тем, что владеют или строят летающие корабли. Корпорация Приобретений, возможно, наиболее известна своими громкими воздушными выходками. Несомненно, ни маги Халруаа, ни члены Культа Дракона никогда не управляли боевым дирижаблем, наполненным [кегоботами](CREATURE:${CREATURE_KEG_ROBOT}). И не давили им [тиранозавра](CREATURE:${CREATURE_TYRANNOSAURUS_REX}).`,
      source: {
        id: SOURCE_AI,
        page: 218,
      },
    },
    {
      header: `Летающая боевая машина`,
      text: `Стандартный дизайн Корпорации Приобретений выглядит как огромный баллон, обтянутый верёвочной сетью. Такелаж соединяет баллон с подвешенным снизу судном из дерева и металла. В центре палубы, прямо под баллоном, находится большая магическая печь, чья огромная подъёмная сила, по слухам, использует пленённых элементалей. Капитан боевого дирижабля управляет магической печью, направляя тягу на винт, расположенный на корме корабля. Управляется корабль рулевым колесом, расположенным в носовой части. Металлические салазки под корпусом позволяют кораблю приземляться.`,
      source: {
        id: SOURCE_AI,
        page: 218,
      },
    },
    {
      header: `Варианты и вооружение`,
      text: `Каждая команда или организация настраивает наступательные возможности своих дирижаблей, но большинство летающих кораблей имеют несколько гарпунных пушек, идеально подходящих для борьбы и сдерживания воздушных угроз. Боевые дирижабли, которые часто попадают в бой, обычно хорошо вооружены другим стрелковым и древковым оружием для боевых действий между кораблями и экипажами.

Независимо от того, финансируются ли они из частных источников или сдаются в аренду Головным офисом на чрезвычайно строгих условиях, боевые дирижабли могут быть оснащены уникальным оборудованием — различными осадными орудиями, подъёмными и грузовыми устройствами и тому подобным, что делает каждый из них уникальным. Тем не мение, самое мощное оружие, представленное на большинстве боевых дирижаблей Корпорации Приобретений — это арбалет зелёного пламени, здоровенная баллиста, чьи стрелы наполнены магической силой и поэтому разрушительно взрываются там, куда они попали.`,
      source: {
        id: SOURCE_AI,
        page: 218,
      },
    },
    {
      header: `Мобильная штаб-квартира`,
      text: `Боевой дирижабль — это, наверное, самый востребованный вариант мобильной штаб-квартиры, доступной для команд Корпорации Приобретений. Универсальность дирижабля позволяет быстро доставлять товары, наёмников и добычу. Такое судно также позволяет быстро уйти от неудачных сделок и неприятных последствий удачных операций.`,
      source: {
        id: SOURCE_AI,
        page: 218,
      },
    },
    {
      header: `Твёрдая туча`,
      text: `Твёрдая туча — это флагман воздушного флота Корпорации Приобретений и поэтому его стоит упомянуть отдельно.

Этот огромный боевой дирижабль напичкан вооружением, а его нос увенчан жестоким копьём для убийства драконов, которое не раз применялось в деле.

Твёрдая туча — ветеран множества боевых операций и при этом он всё ещё цел.

Помимо рассказов о его подвигах, о самом чудесном корабле ходят разные слухи. Стоит ли верить тому, что его машина питается силой пленённого [ифрита](CREATURE:${CREATURE_EFREETI})? Может быть, по другим слухам, в его трюме есть клоны всего руководства Корпорации Приобретений, включая самого Омина Драна? Правда ли, что на его камбузе еду готовит ужасный повар-призрак? Ответы лежат под палубами Твёрдой тучи, и мало кто имел возможность узнать правду.`,
      source: {
        id: SOURCE_AI,
        page: 218,
      },
    },
    vehicleRules,
  ],
  note: `Через снежные облака, окружающие замок на безлюдном холме, просвечивается силуэт. Что-то столь огромное, что даже не верится что оно вообще может летать.`,
  sizeType: SIZE_GARGANTUA,
  proportions: [80, 20],
  cargo: {
    unitId: MASS_UNIT_TON,
    value: 1,
  },
  creatureCapacity: {
    crew: 20,
    passengers: 10,
  },
  travelSpeed: 9,
  source: {
    id: SOURCE_AI,
    page: 219,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 20,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_INCAPACITATED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_STUNNED,
    CONDITION_UNCONSCIOUS,
  ],
  actionsInfo: `На своём ходу ★ТРАНСПОРТ★ может совершать

* 3 действия, если на нём 20 или больше экипажа,
* 2 действия, если на нём 10 или больше экипажа,
* 1 действие, если на нём меньше 10 экипажа,
* 0 действий, если на нём нет экипажа`,
  actionList: [
    {
      name: 'Выстрел баллисты',
      description: `★ТРАНСПОРТ★ стреляет из _Гарпунных пушек_.`,
    },
    {
      name: 'Выстрел арбалета зелёного пламени',
      description: `★ТРАНСПОРТ★ стреляет из _Арбалета зелёного пламени_.`,
    },
    {
      name: 'Передвижение',
      description: `★ТРАНСПОРТ★ использует _Рубку_ для передвижения с помощью _Винта_.

Если ★ТРАНСПОРТ★ входит в пространство Большого или меньшего существа, то это существо автоматически толкается в сторону ★его★ _Винтов_. Существо должно пройти испытание Ловкости СЛ 15 или получить 5 (1к10) дробящего урона.`,
    },
    {
      name: 'Подтягивание гарпуна',
      description: `★ТРАНСПОРТ★ может подтянуть все схваченные ★им★ цели на расстояние до 30 футов к себе.`,
    },
  ],
  hull: {
    ac: 15,
    hp: 200,
    damageThreshold: 15,
  },
  controlList: [
    {
      name: 'Рубка',
      ac: 15,
      hp: 200,
      description: `★ТРАНСПОРТ★ передвигается со скоростью, развиваемой _Винтом_, с одним поворотом на 90°. Если _Рубка_ разрушена, ★ТРАНСПОРТ★ не может поворачивать.`,
    },
    {
      name: 'Баллон',
      ac: 12,
      hp: 75,
      description: `Если _Баллон_ разрушен, ★ТРАНСПОРТ★ не может поддерживать высоту.`,
    },
  ],
  movementList: [
    {
      name: 'Винт',
      ac: 12,
      hp: {
        value: 100,
        comment: '−5 футов за каждые полученные 25 урона',
      },
      speedList: [
        {
          comment: 'воздушная',
          speed: {
            [SPEED_FLY]: [
              80,
              {
                value: 50,
                comment: 'против ветра',
              },
              {
                value: 100,
                comment: 'по ветру',
              },
            ],
          },
        },
      ],
    },
  ],
  weaponList: [
    {
      name: 'Арбалет зелёного пламени',
      ac: 15,
      hp: 75,
      action: {
        description: `Если атака промахивается, Мастер определяет куда она попала. Все существа в пределах 10 футов от этой точки должны пройти испытание Ловкости СЛ 15, чтобы увернуться от осколков болта, получая 5 (1к10) колющего урона и 5 (1к10) урона огнём при провале.`,
        attack: {
          type: ATTACK_RANGE_WEAPON,
          bonus: 8,
          range: {
            min: 60,
            normal: 200,
            max: 800,
          },
          hit: [
            {
              type: DAMAGE_PIERCING,
              diceCount: 3,
              diceType: 10,
            },
            {
              type: DAMAGE_FIRE,
              diceCount: 4,
              diceType: 10,
            },
          ],
        },
      },
    },
    {
      name: 'Гарпунная пушка',
      count: 3,
      ac: 15,
      hp: 50,
      action: {
        description: `Цель схвачена (СЛ побега 16). Пока цель схвачена, скорость цели уменьшена вдвое и не может двигаться прочь от ★ТРАНСПОРТ★. Каждая из _Гарпунных пушек_ может держать одну цель. Удерживание цели _Гарпунной пушкой_ не уменьшает скорость ★ТРАНСПОРТ★.`,
        attack: {
          type: ATTACK_RANGE_WEAPON,
          bonus: 8,
          range: {
            normal: 120,
            max: 480,
          },
          hit: {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 10,
          },
        },
      },
    },
  ],
}
