import React from 'react'

import Vehicle from '@/components/Vehicle'
import VehicleCatalog from '@/components/VehicleCatalog'

import {vehicleCollection} from '@/constants/vehicleList'
import vehicleImageCollection from '@/constants/images/vehicleImageCollection'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Техника',
  description: 'Каталог всего техники в Dungeons & Dragons',
  img: null,
}

const VehiclePageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={vehicleCollection}
    ItemComponent={Vehicle}
    itemImageCollection={vehicleImageCollection}
    ListComponent={VehicleCatalog}
    {...props}
  />
)

export default VehiclePageTemplate
