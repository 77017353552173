import React, { Component } from 'react';
import PropTypes from "prop-types"

import {vehicleCollection} from "@/constants/vehicleList"

import VehicleComponent from "./VehicleComponent"

class VehicleContainer extends Component {
  render () {
    const { id } = this.props;
    const vehicle = vehicleCollection[id];

    return (
      <VehicleComponent vehicle={vehicle}/>
    )
  }
}

VehicleContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default VehicleContainer
