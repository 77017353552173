import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import vehicleImageCollection from '@/constants/images/vehicleImageCollection'
import {vehicleCollection} from '@/constants/vehicleList'

const VehicleImageListContainer = ({ vehicleId }) => {
  if (vehicleId) {
    const vehicle = vehicleCollection[vehicleId]
    const imageList = vehicleImageCollection[vehicleId]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...vehicle}
        />
      )
    }

    return null
  }

  return null
}

VehicleImageListContainer.propTypes = {
  vehicleId: PropTypes.string.isRequired,
}

export default VehicleImageListContainer
