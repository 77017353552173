const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_AI} = require('./../../../../sourceList')
const {VEHICLE_MECHANICAL_BEHOLDER} = require('./../../../../vehicleIdList')
const {vehicleRules} = require('./../../../vehicleCommonDescription')
const {
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../../paramList')
const {
  SPEED_DIG,
  SPEED_FLY,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_INCAPACITATED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')

module.exports = {
  name: 'Механический бехолдер',
  nameEn: 'Mechanical Beholder',
  id: VEHICLE_MECHANICAL_BEHOLDER,
  description: [
    `Механический бехолдер выглядит как огромное око-тиран, со смотровым окном вместо центрального глаза.

Эта частично транспортная, частично лабораторная и частично осадная машина может перевозить до шести гуманоидов по любой местности или через любую среду, в том числе под водой и через твёрдый камень. Изначально управлять механическими бехолдерами было сложно и часто опасно манипулируя шестернями, рычагами и кнопками, но последние модели оснащены магическим голосовым управлением, хотя и не всегда точным.

# Оборудование

Механического бехолдера можно оснастить множеством датчиков и зондов, для самых разных задач:  определения алхимического состава, улавливания магических аур или мастерского приготовления сухого мартини.

В его арсенал входит луч распада, смоделированный по образцу его чудовищного тёзки (который из соображений гуманности рекламируется как разработанный исключительно для уборки мусора). Иллюминаторы и люки позволяют персонажам внутри бехолдера совершать дальнобойные и магические атаки изнутри.`,
    vehicleRules,
  ],
  note: `Щелчок, с которым пробуждается к жизни механический бехолдер, звучит так, будто бы сама смерть щёлкнула пальцами.`,
  sizeType: SIZE_HUGE,
  proportions: [15, 15],
  cargo: 'снаряжение команды и экипажа',
  creatureCapacity: {
    crew: 1,
    passengers: 5,
  },
  travelSpeed: 3,
  source: {
    id: SOURCE_AI,
    page: 220,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 18,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_INCAPACITATED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_STUNNED,
    CONDITION_UNCONSCIOUS,
  ],
  actionsInfo: `На своём ходу ★ТРАНСПОРТ★ может совершать 1 действие. ★Он★ не может совершать действий, если на ★нём★ нет экипажа.`,
  actionList: [
    {
      name: 'Лучи из глаз',
      description: `★ТРАНСПОРТ★ использует _Глазолучевые щупальца_.`,
    },
    {
      name: 'Передвижение',
      description: `★ТРАНСПОРТ★ использует _Рубку_ для передвижения с помощью _Магической силовой установки_.`,
    },
  ],
  hull: {
    ac: 18,
    hp: 200,
    damageThreshold: 10,
  },
  controlList: [
    {
      name: 'Рубка',
      ac: 16,
      hp: 25,
      description: `★ТРАНСПОРТ★ передвигается со скоростью, развиваемой _Магической силовой установкой_, с одним поворотом на 90°.

_Рубку_ можно атаковать только если _Корпус_ получил не менее 100 урона. Если _Рубка_ разрушена, ★ТРАНСПОРТ★ не может передвигаться.`,
    },
  ],
  movementList: [
    {
      name: 'Магическая силовая установка',
      ac: 16,
      hp: {
        value: 100,
        comment: '−5 футов за каждые полученные 25 урона',
      },
      speedList: [
        {
          comment: 'магическая',
          speed: {
            [SPEED_FLY]: {
              value: 30,
              isFloating: true,
            },
            [SPEED_DIG]: 30,
            [SPEED_SWIM]: 30,
          },
        },
      ],
    },
  ],
  weaponList: [
    {
      name: 'Глазолучевые щупальца',
      ac: 14,
      hp: 50,
      action: {
        name: 'Магическая атака',
        description: `★ТРАНСПОРТ★ выстреливает магическими лучами из глаз по трём целям, видимых экипажем в пределах 120 футов.

1. **Луч расщепления**
    * Если цель — существо, оно должно пройти испытание Ловкости СЛ 15 или получить 18 (4к8) урона силовым полем. Если этот урон снижает хиты существа до 0, его тело становится кучкой серой пыли.
    * Если цель — Большой или меньший немагический предмет или творение магической силы, она расщепляется без испытания.
    * Если цель — Огромный или больший предмет или творение магической силы, она расщепляется без испытания, луч расщепляет объём куба с длиной ребра 10 футов.
2. **Луч бессилия.** Целевое существо должно пройти испытание Телосложения СЛ 15, получая 18 (4к8) урона некротической энергией при провале, или половину урона при успехе.
3. **Парализующий луч.** Целевое существо должно пройти испытание Телосложения СЛ 15, иначе станет парализованным на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      },
    },
  ],
}
