const listToCollectionById = require('./../../utils/listToCollectionById')
const {GENDER_MALE} = require('./../genderList')
const mentalParamIdList = require('./../mentalParamIdList')

const vehicleRawList = require('./vehicleRawList')

const emptyMental = mentalParamIdList.reduce(
  (obj, paramId) => ({
    ...obj,
    [paramId]: 0,
  }),
  {}
)

const vehicleList = vehicleRawList.map(
  creature => ({
    ...creature,
    genderId: creature.genderId || GENDER_MALE,
    isVehicle: true,
    params: {
      ...creature.params,
      ...emptyMental,
    },
  })
)

module.exports = vehicleList

module.exports.vehicleCollection = listToCollectionById(vehicleList)
