import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME';
import {FILTER_TYPE_INPUT} from '@/constants/filterTypes'

export default [
  {
    label: 'Введите название техники на русском или английском',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: ''
  },
];
