import React from "react"
import PropTypes from "prop-types"

import DescriptionList from "@/components/DescriptionList"
import Note from "@/components/Note"
import PageHeader from "@/components/PageHeader"
import StatBlock from "@/components/StatBlock"

import VehicleImageList from "./components/VehicleImageList"

import "./VehicleStyles.less"

const VehicleComponent = ({vehicle, ...rest}) => (
  <section className='Vehicle'>
    <PageHeader {...vehicle}/>
    <section className='Vehicle_body'>
      <StatBlock
        data={vehicle}
        hideMentalParamModifiers={true}
        {...rest}
      />
      <Note {...vehicle} />
      <DescriptionList {...vehicle} />
      <VehicleImageList vehicleId={vehicle.id} />
    </section>
  </section>
)

VehicleComponent.propTypes = {
  vehicle: PropTypes.object.isRequired,
}

export default VehicleComponent
