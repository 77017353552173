const {
  SOURCE_AI,
  SOURCE_DMG,
} = require('./../../sourceList')
const {
  VEHICLE_BATTLE_BALLOON,
  VEHICLE_MECHANICAL_BEHOLDER,
} = require('./../../vehicleIdList')

const Battle_Balloon_AI_img = require('./../../../images/vehicles/ai/battle_balloon.jpg')
const Battle_Baloon_DMG_img = require('./../../../images/vehicles/dmg/battle_baloon.jpg')
const Mechanical_beholder_AI_img = require('./../../../images/vehicles/ai/mechanical_beholder.jpg')

module.exports = {
  [VEHICLE_MECHANICAL_BEHOLDER]: {
    src: Mechanical_beholder_AI_img,
    source: {
      id: SOURCE_AI
    }
  },
  [VEHICLE_BATTLE_BALLOON]: [
    {
      src: Battle_Balloon_AI_img,
      source: {
        id: SOURCE_AI
      }
    },
    {
      src: Battle_Baloon_DMG_img,
      text: 'Воздушный корабль',
      source: {
        id: SOURCE_DMG
      }
    },
  ],
}
