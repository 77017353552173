const {SOURCE_AI} = require('./../sourceList')
const {
  VEHICLE_BATTLE_BALLOON,
  VEHICLE_MECHANICAL_BEHOLDER,
} = require('./../vehicleIdList')

const vehicleRules = {
  header: `Правила использования техники`,
  text: `[Боевые дирижабли](VEHICLE:${VEHICLE_BATTLE_BALLOON}) и [механические бехолдеры](VEHICLE:${VEHICLE_MECHANICAL_BEHOLDER}) — это техника и они используются по специальным правилам.

Говоря точнее, у техники есть шесть характеристик (Сила, Ловкость, Телосложение, Интеллект, Мудрость и Харизма) и соответствующие модификаторы.

* **Сила** техники выражается в её размере и массе.
* **Ловкость** представляет то, насколько ей легко управлять.
* **Телосложение** техники показывает её прочность и надёжность конструкции.

Техника обычно имеет 0 в Интеллекте, Мудрости и Харизме. Если какая-то характеристика техники равна 0, она автоматически проваливает все проверки и испытания, связанные с этой характеристикой.

Сколько у техники экипажа и какие действия она может совершать на своём ходу — описано в её статблоке.`,
  source: {
    id: SOURCE_AI,
    page: 219,
  },
}

module.exports = {
  vehicleRules,
}
